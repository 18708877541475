







































































import { Vue, Component } from 'vue-property-decorator'
import Container from '@/components/Container/index.vue'
import { IFilterData } from '@/constant/models'
import Pagination from '@/components/Pagination/index.vue'
import CallRecordsSearchHeader from '@/views/customer/components/CallRecordsSearchHeader/index.vue'
import PlayRecordDialog from '@/views/customer/components/PlayRecordDialog/index.vue'
import { WebCallApi } from '@/proxy'
import DateUtil from '@/utils/date'
import { DpBackendWebCallsWebCallRecordDto } from '@/proxy/models'

@Component({
  name: 'CustomerCallRecords',
  components: {
    PlayRecordDialog,
    CallRecordsSearchHeader,
    Container,
    Pagination
  }
})
export default class extends Vue {
  private page = 1
  private pageSize = 20
  private totalCount = 0
  private playRecordVisible = false

  private recordsList: DpBackendWebCallsWebCallRecordDto[] = []
  private listLoading = false
  private filter: IFilterData = {}
  private currentRecord: DpBackendWebCallsWebCallRecordDto = {}

  created() {
    this.refreshRecordsList()
  }

  private async refreshRecordsList(filter?: IFilterData, byPagination = false, refresh = false) {
    if (refresh) {
      this.page = 1
    }
    if (!byPagination) {
      this.filter = filter ?? {}
    }
    this.listLoading = true
    const { data } = await new WebCallApi().apiAppWebCallGet(
      filter?.filter,
      filter?.start ? DateUtil.dateFormat(filter?.start) : undefined,
      filter?.end ? DateUtil.dateFormat(filter?.end) : undefined,
      filter?.abpUserIds,
      undefined,
      (this.page - 1) * this.pageSize,
      this.pageSize
    )
    this.listLoading = false
    this.recordsList = data.items
    this.totalCount = data.totalCount
  }

  private playCallRecord(record: DpBackendWebCallsWebCallRecordDto) {
    this.currentRecord = record
    this.playRecordVisible = true
  }
}
